
<template>
<div class="py-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Claim overview</h5>
      <div class="card mb-5">
        <div class="card-body">
          <div class="row gy-3 justify-content-between align-items-start">
            <div class="col-10 col-sm-8">
              <div class="row g-2">
                <div class="col-6"> 
                  <span>Claim ID</span>
                  <h6> {{item.friendly_id}} </h6>
                </div> 
                <div class="col-6"> 
                  <span>Status</span>
                  <div><span v-html="statusBadge(item.status)"> </span></div>
                </div>
                <div class="col-6"> 
                  <span>Loss type</span>
                  <h6> {{item.loss_type.replace(/_/g, ' ')}} </h6>
                </div>
                <div class="col-sm-6"> 
                  <span>Loss date</span>
                  <h6> {{$filters.date_time(item.loss_date, 1)}} </h6>
                </div>
                <div class="col-sm-6"> 
                  <span>Paypal email</span>
                  <h6> {{item.paypal_email}} </h6>
                </div>
                <div class="col-sm-6"> 
                  <span>Digital signature</span>
                  <h6> {{ item.digital_signature}} </h6>
                </div>
                <div class="col-12"> 
                  <span>Description</span>
                  <div> {{ item.description}} </div>
                </div>
                <div class="col-12"> 
                  <div><strong>Item:</strong> <router-link :to="`/items/${item.item.id}`"> {{ item.item.title}}</router-link> </div>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-sm-end">
              <div class="dropdown no-arrow">
                <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a @click.prevent="popupModalUpdate = true" class="dropdown-item" href="javascript:void(0)" >
                    Update status </a>
                  </li>
                  <li>
                    <router-link :to="`/items/${item.item_id}`" class="dropdown-item">
                     View item </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <h5 class="mb-3">Claim photos</h5>
      <div class="card mb-5">
        <div class="card-body">
         <div class="row g-4">
            <div v-for="(file, fIndex) in item.files" :key="fIndex"
               class="col-12 col-lg-3 col-sm-6" >
              <div>
                <div @click="showImg(fIndex)" class="cursor-pointer">
                  <img :src="absoluteUrl(file.path)" class="mb-2 w-100" />
                </div>
                <span>{{file.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
          <div class="card-header">
              <h4 class="mb-0">Claim service</h4>
          </div>
          <div class="card-body">
              <div v-if="item.partner">
                <div class="row">
                  <div class="col-sm-5 col-md-4">
                    <router-link :to="`/users/${item.partner.id}`">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xl me-2 img-thumbnail rounded-circle flex-shrink-0">
                          <img :src="absoluteUrl(item.partner.image)" alt="" class="member-img img-fluid d-block rounded-circle">
                        </div>
                        <div>
                          <h5 class="mb-2">{{item.partner.business_name}}</h5>
                          <p class="mb-0">{{item.partner.first_name}} {{item.partner.last_name}}</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="col-sm-7 col-md-8">
                    <div v-if="item.service_charge"><strong>Service amount: </strong> ${{$filters.money_format(item.service_charge)}}</div>
                    <div v-if="item.started_at"><strong>Started at: </strong> {{$filters.date_time(item.started_at, 1)}}</div>
                    <div v-if="item.completed_at"><strong>Completed at: </strong> {{$filters.date_time(item.completed_at, 1)}}</div>
                  </div>
                </div>
              </div> 
          </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <vue-easy-lightbox v-if="item" :visible="lightbox.visible" :imgs="lightboxFiles"
    :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>

  <b-modal hide-footer v-model="popupModalUpdate" title="Update claim">
    <claim-form v-if="popupModalUpdate" @closeMe="initializeAll()"
      :editItem="item" @updateItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import VueEasyLightbox from "vue-easy-lightbox";
import ClaimForm from '@/components/forms/ClaimForm.vue'

export default {
  components:{
    IsLoading,
    VueEasyLightbox,
    ClaimForm
  },
  data(){
    return {
      isLoading: true,
      item: null,
      lightbox:{
        index: null,
        visible:false,
      },
      popupModalUpdate: false,
    }
  },
  watch: {
    '$route.params.claimId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    lightboxFiles(){
      return this.item.files.map(file => {
        if(file.is_video){
          return {
            title: file.description,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.description,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.item, item)
    },
    showImg(index) {
      this.lightbox = { index: index,visible: true};
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/claims/${this.$route.params.claimId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: "/error-404"})
        }
      })
    },
    initializeAll(){
      this.popupModalUpdate = false;
      this.lightbox = { index: null, visible:false}
    },
  },
  created(){
    this.fetchItem()
  }
}

</script>

<style lang="scss">

</style>
